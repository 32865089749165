.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(103, 179, 98, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 179, 98, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #67B362;
  border-color: #67B362;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-primary:hover {
  color: #fff;
  background-color: #53a14e;
  border-color: #4f984a;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(103, 179, 98, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #67B362;
  border-color: #67B362;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4f984a;
  border-color: #4a9046;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(103, 179, 98, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-secondary:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-success:hover {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #409440;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #6CCAC9;
  border-color: #6CCAC9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-info:hover {
  color: #212529;
  background-color: #50c0bf;
  border-color: #47bcbb;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(108, 202, 201, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #6CCAC9;
  border-color: #6CCAC9;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #47bcbb;
  border-color: #41b5b4;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 202, 201, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #F9BB29;
  border-color: #F9BB29;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-warning:hover {
  color: #212529;
  background-color: #f5ae07;
  border-color: #e8a507;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(249, 187, 41, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #F9BB29;
  border-color: #F9BB29;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e8a507;
  border-color: #dc9c06;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(249, 187, 41, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #EE695B;
  border-color: #EE695B;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-danger:hover {
  color: #fff;
  background-color: #ea4938;
  border-color: #e93f2d;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(238, 105, 91, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #EE695B;
  border-color: #EE695B;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e93f2d;
  border-color: #e83421;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(238, 105, 91, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-dark:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-primary {
  color: #67B362;
  background-color: transparent;
  background-image: none;
  border-color: #67B362;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #67B362;
  border-color: #67B362;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 179, 98, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #67B362;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #67B362;
  border-color: #67B362;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(103, 179, 98, 0.5);
}

.btn-outline-secondary {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-success {
  color: #5cb85c;
  background-color: transparent;
  background-image: none;
  border-color: #5cb85c;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #5cb85c;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-outline-info {
  color: #6CCAC9;
  background-color: transparent;
  background-image: none;
  border-color: #6CCAC9;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #6CCAC9;
  border-color: #6CCAC9;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 202, 201, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #6CCAC9;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #6CCAC9;
  border-color: #6CCAC9;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 202, 201, 0.5);
}

.btn-outline-warning {
  color: #F9BB29;
  background-color: transparent;
  background-image: none;
  border-color: #F9BB29;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #F9BB29;
  border-color: #F9BB29;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 187, 41, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #F9BB29;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #F9BB29;
  border-color: #F9BB29;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(249, 187, 41, 0.5);
}

.btn-outline-danger {
  color: #EE695B;
  background-color: transparent;
  background-image: none;
  border-color: #EE695B;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #EE695B;
  border-color: #EE695B;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 105, 91, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #EE695B;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #EE695B;
  border-color: #EE695B;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(238, 105, 91, 0.5);
}

.btn-outline-light {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #67B362;
  background-color: transparent;
}

.btn-link:hover {
  color: #468741;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .action-login, .action-password, .form-signin .retrieve-password .submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block, .action-login, .action-password, .form-signin .retrieve-password .submit {
  display: block;
  width: 100%;
}

.btn-block + .btn-block, .action-login + .btn-block, .action-password + .btn-block, .form-signin .retrieve-password .submit + .btn-block, .btn-block + .action-login, .action-login + .action-login, .action-password + .action-login, .form-signin .retrieve-password .submit + .action-login, .btn-block + .action-password, .action-login + .action-password, .action-password + .action-password, .form-signin .retrieve-password .submit + .action-password, .form-signin .retrieve-password .btn-block + .submit, .form-signin .retrieve-password .action-login + .submit, .form-signin .retrieve-password .action-password + .submit, .form-signin .retrieve-password .submit + .submit {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block, input[type="submit"].action-login, input[type="submit"].action-password, .form-signin .retrieve-password input[type="submit"].submit,
input[type="reset"].btn-block,
input[type="reset"].action-login,
input[type="reset"].action-password,
.form-signin .retrieve-password input[type="reset"].submit,
input[type="button"].btn-block,
input[type="button"].action-login,
input[type="button"].action-password,
.form-signin .retrieve-password input[type="button"].submit {
  width: 100%;
}

#main-content {
  min-height: calc(100vh - 87px);
}

.login-body {
  background-color: #f1f2f7;
}

.action-login, .action-password {
  font-weight: 300;
  text-transform: uppercase;
}

.form-signin .title {
  margin: 0;
  padding: 20px 15px;
  text-align: center;
  background: #A8ABBA;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 500;
  font-family: inherit;
}

.form-signin .checkbox {
  margin-bottom: 14px;
}

.form-signin .checkbox {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #b6b6b6;
  font-weight: 300;
}

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="text"], .form-signin input[type="password"] {
  margin-bottom: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #eaeaea;
  box-shadow: none;
  font-size: 12px;
  padding: 2px 4px;
  width: 100%;
}

.form-signin .action-login,
.form-signin button.submit,
.form-signin .action-password {
  background: #67B362;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 20px;
}

.form-signin .action-login:hover,
.form-signin button.submit:hover,
.form-signin .action-password:hover {
  background-color: #468741;
  color: #fff;
}

.form-signin p {
  text-align: center;
  margin: 0;
}

.label, label {
  font-weight: 500;
  display: block;
  padding: 0;
  margin-bottom: .25rem;
  text-align: left;
}

.form-signin a {
  color: #41cac0;
}

.form-signin a:hover {
  color: #b6b6b6;
}

.login-wrap {
  padding: 20px;
}

.login-social-link {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 15px;
}

.login-social-link a {
  color: #fff;
  padding: 15px 28px;
  border-radius: 4px;
}

.login-social-link a:hover {
  color: #fff;
}

.login-social-link a i {
  font-size: 20px;
  padding-right: 10px;
}

.login-social-link a.facebook {
  background: #5193ea;
  margin-right: 22px;
  box-shadow: 0 4px #2775e2;
  float: left;
}

.login-social-link a.twitter {
  background: #44ccfe;
  box-shadow: 0 4px #2bb4e8;
  float: left;
}

.set-pw-intro {
  padding: 1rem;
  font-weight: 400;
  color: #515B6B;
  text-align: center;
  max-width: 50rem;
  margin: 100px auto 0;
}

.set-pw-intro h3 {
  color: #67B362;
}

.set-pw-intro p {
  margin-bottom: 0;
}

.set-pw-intro + .password-reset.form-signin {
  margin-top: 1rem;
  max-width: 30rem;
}

.set-pw-intro + .password-reset.form-signin .action-password.btn {
  max-width: 16rem;
  margin: 0 auto 20px;
}

#authentication {
  background-color: #f1f2f7;
}

#main-content-wc-0 .brand {
  max-width: 375px;
  width: 100%;
  margin: -.6rem auto 2rem;
  background: #4F5A69;
  border-radius: 0 0 0.2rem 0.2rem;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
  padding: 1rem;
  border: 1px solid #39414c;
  color: #fff;
  font-weight: 400;
}

.form-signin, .form-signout {
  max-width: 375px;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.form-signin .fields {
  text-align: left;
  padding: 20px 3rem;
}

.form-signin .message-container,
.form-signin .actions {
  padding-left: 20px;
  padding-right: 20px;
}

.form-signin .text p, .form-signin .password-rules {
  color: #515B6B;
  font-size: 90%;
}

.form-signin .message-container {
  margin: 10px 0 25px;
}

.form-signin .retrieve-password {
  padding: 10px 0;
}

.form-signin .retrieve-password div.email {
  padding: 0 3rem 15px;
  text-align: left;
}

.form-signin .retrieve-password div.email > span {
  color: #444;
  font-weight: 500;
  font-size: 90%;
}

.form-signin .retrieve-password .submit {
  border: 1px solid transparent;
  color: #fff;
  background-color: #67B362;
  border-color: #57a952;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-signin .retrieve-password .submit:hover {
  color: #fff;
  background-color: #4f984a;
  border-color: #42803e;
}

.form-signin .retrieve-password .submit:focus, .form-signin .retrieve-password .submit.focus {
  color: #fff;
  background-color: #4f984a;
  border-color: #42803e;
}

.form-signin .retrieve-password .submit:active, .form-signin .retrieve-password .submit.active,
.open > .form-signin .retrieve-password .submit.dropdown-toggle {
  color: #fff;
  background-color: #4f984a;
  border-color: #42803e;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.form-signin .retrieve-password .submit:active:hover, .form-signin .retrieve-password .submit:active:focus, .form-signin .retrieve-password .submit:active.focus, .form-signin .retrieve-password .submit.active:hover, .form-signin .retrieve-password .submit.active:focus, .form-signin .retrieve-password .submit.active.focus,
.open > .form-signin .retrieve-password .submit.dropdown-toggle:hover,
.open > .form-signin .retrieve-password .submit.dropdown-toggle:focus,
.open > .form-signin .retrieve-password .submit.dropdown-toggle.focus {
  color: #fff;
  background-color: #42803e;
  border-color: #2b5428;
}

.form-signin .retrieve-password .submit.disabled:focus, .form-signin .retrieve-password .submit.disabled.focus, .form-signin .retrieve-password .submit:disabled:focus, .form-signin .retrieve-password .submit:disabled.focus {
  background-color: #67B362;
  border-color: #57a952;
}

.form-signin .retrieve-password .submit.disabled:hover, .form-signin .retrieve-password .submit:disabled:hover {
  background-color: #67B362;
  border-color: #57a952;
}

.form-signin .actions {
  text-align: center;
}

.password-reset .password-rules {
  padding: 25px 20px 0;
}

.password-reset .username .value {
  color: #515B6B;
  font-size: .9rem;
}

.password-reset .actions {
  padding-bottom: 25px;
}

.password-reset .fields > .field {
  margin-bottom: 15px;
}

.password-reset .label label, .password-reset .value input {
  margin-bottom: 0;
  font-size: 90%;
}

.form-reset #retrieve-password {
  text-align: center;
}

header#main-header {
  display: none;
}

@media (max-width: 600px) {
  .set-pw-intro {
    margin-top: 1rem;
  }
  .set-pw-intro h3 {
    padding-left: 0 !important;
  }
}

.eula span.value {
  display: none;
}

.eula .label {
  color: black;
  white-space: normal;
  font-size: .9rem;
  line-height: 1.5;
}
